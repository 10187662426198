var idPlan;

$(function() {
	initiMenuXL();
	initFavori();
 });

function initiMenuXL(){
	$("#li-plan").mouseenter(function() {
		activeMenuXL($(this),$("#xl-plan-maison"));
		$(".encoreActif").removeClass("encoreActif");
	}).mouseleave(function() {
		desactiveMenuXL($(this));
		$(this).addClass("encoreActif");
	});
	$("#li-permis").mouseenter(function() {
		activeMenuXL($(this),$("#xl-permis"));
		$(".encoreActif").removeClass("encoreActif");
	}).mouseleave(function() {
		desactiveMenuXL($(this));
		$(this).addClass("encoreActif");
	});
	$("#li-maitrise").mouseenter(function() {
		activeMenuXL($(this),$("#xl-construction"));
		$(".encoreActif").removeClass("encoreActif");
	}).mouseleave(function() {
		desactiveMenuXL($(this));
		$(this).addClass("encoreActif");
	});
	$(".sous-menu-xl").mouseenter(function() {
		activeMenuXL($(".encoreActif"),$(this));
	}).mouseleave(function() {
		desactiveMenuXL($(".encoreActif"));
	});
}

function activeMenuXL(elementMenu,elementXL){
	$("#menu-xl").removeClass("hide");
	$(".sous-menu-xl").addClass("hide");
	elementXL.removeClass("hide");
	elementMenu.addClass("menu_over"); 
}
	 
function desactiveMenuXL(elementMenu){
	$("#menu-xl").addClass("hide");
	elementMenu.removeClass("menu_over");
}

function affichMaskPanier(){
	 return affichMaskPanierBool($("#panier-main-zone").is(":visible"));
}

function affichMaskPanierBool(bool){
	 if(bool){
		 $("#panier-main-zone").addClass("hide");
		 $("#panier-titre").removeClass("deplie");
		 $("#img-ouvre-panier").removeClass("hide");
		 $("#img-ferme-panier").addClass("hide");
	 }
	 else{
		 $("#panier-main-zone").removeClass("hide");
		 $("#panier-titre").addClass("deplie");
		 $("#img-ouvre-panier").addClass("hide");
		 $("#img-ferme-panier").removeClass("hide");
	 }
}

function addDeleteFavori(idPlanFavori){
	$.ajax({
		  type: 'POST',
		  dataType: 'json',
		  url: '/plan/favori',
		  data:  {"idPlan" : idPlanFavori} ,
		  success: function(response) {  
			 if(response.favori == "ajout"){
				 $("#favori-plan-" + idPlanFavori).attr("src", "/img/btn_pct_favoris_ajoute.png");
				 $("#detail-plan-favori-add").addClass("hide");
				 $("#detail-plan-favori-delete").removeClass("hide");
				 majApresFavori(response);
				 
			 }
			 else if(response.favori == "delete"){
				 $("#favori-plan-" + idPlanFavori).attr("src", "/img/btn_pct_favoris_pour_ajout.png");
				 $("#detail-plan-favori-add").removeClass("hide");
				 $("#detail-plan-favori-delete").addClass("hide");
				 majApresFavori(response);
			 }
			 else{
				 idPlan = idPlanFavori;
				 $( "#dialog-inscription" ).removeClass("hide");
				 $( "#dialog-inscription" ).dialog({ width: 700});
				 $( "#panel-login-register").tabs();
			 }
		  }
	});
}

function majApresFavori(response){
	 $("#non-connecte-user").addClass("hide");
	 $("#connecte-user").removeClass("hide");
	 $("#favori-header").removeClass("hide");
	 $("#favori-nombrePlan").text(response.nbFavori);
}

function initFavori(){
	$('#favori-inscription-form').on('submit', function(e) {
	    e.preventDefault();
		var formData = $("#favori-inscription-form").serialize();
		$.ajax({
			  type: 'POST',
			  dataType: 'text',
			  url: '/leads-favori/register',
			  data:  formData ,
			  success: function(response) {
				  $( "#dialog-inscription" ).dialog( "close" );
				  addDeleteFavori(idPlan);
			  },
			  error: function(request, status, error) {   	
				majErreurConnect(request, status, error);
			  }
		});
	});
	
	$('#favori-login-form').on('submit', function(e) {
	    e.preventDefault();
		var formData = $("#favori-login-form").serialize();
		$.ajax({
			  type: 'POST',
			  dataType: 'text',
			  url: '/leads-favori/login',
			  data:  formData ,
			  success: function(response) {
				  $( "#dialog-inscription" ).dialog( "close" );
				  addDeleteFavori(idPlan);
			  },
			  error: function(request, status, error) {   	
				majErreurConnect(request, status, error);
			  }
		});
	});
}

function acceptRgpd(){
	$("#rgpd").removeClass("show");
	$("#rgpd").addClass("hide");
	$.ajax({
    	type: 'GET',
        dataType: 'json',
        url: '/rgpd',
        data:  {} ,
        success: function(response) {
        	console.log(response.cookie); 
		}
	});
}

function majErreurConnect(request, status, error){
	$( ".errorValid" ).remove();
	var response = JSON.parse(request.responseText);
	$.each( response, function( key, value ) {
		$( "#" + key ).after( "<small class='errorValid rouge padding-left-15 tres-petit'>" + value[0] + "</small>" );
	});
}
 $(function() {
	 $("#accueilTab").tabs();
	 
	$('.jcarousel').jcarousel({
	wrap: 'circular',
	scroll: 1,
	animation: 2000
	});

	$('.jcarousel-pagination').on('jcarouselpagination:active', 'a', function() {
		$(this).addClass('active');
	}).on('jcarouselpagination:inactive', 'a', function() {
		$(this).removeClass('active');
	}).jcarouselPagination({
		'perPage': 1,
		item: function(page,carouselItems) {
			return '<a href="#' + page + '"> &nbsp; </a>';
		}
	});
	$('.jcarousel-prev').click(function() {
		$('.jcarousel').jcarouselAutoscroll('stop');
		$('.jcarousel').jcarousel('scroll', '-=1');
	});
	$('.jcarousel-next').click(function() {
		$('.jcarousel').jcarouselAutoscroll('stop');
		$('.jcarousel').jcarousel('scroll', '+=1');
	});
	$('.jcarousel').jcarouselAutoscroll({
		target: '+=1',
		interval: 5000
	}); 
	
	$('.carousel-video').jcarousel({
		wrap: 'circular',
		scroll: 1,
		animation: 500
		});
	$('.jcarousel-prev-mini').click(function() {
		$('.carousel-video').jcarousel('scroll', '-=1');
	});
	$('.jcarousel-next-mini').click(function() {
		$('.carousel-video').jcarousel('scroll', '+=1');
	});

 });